import {appLocalStorage, appSessionStorage} from '../../shared/services';

const initialState = () => {
  return {
    showCartPopup: false,
    showProductAdded: false,
    addToCartError: null,
    cartMessage: null,
    mergingQuote: false,
    cartTotals: appSessionStorage.getItem('checkout-cart-totals', null),
    loadingCartTotals: !appSessionStorage.getItem('checkout-cart-totals-ready', false),
    shippingMethods: appSessionStorage.getItem('checkout-cart-estimate-shipping', []),
    headerTotals: appLocalStorage.getItem('checkout-cart-header-totals', null),
    defaultCarrierCode: null,
    defaultMethodCode: null,
  }
};

const state = initialState();

const mutations = {
  SET_CART_TOTALS(state, data) {
    appSessionStorage.setItem('checkout-cart-totals', data);
    state.cartTotals = data;
  },
  SET_HEADER_TOTALS(state, data) {
    appLocalStorage.setItem('checkout-cart-header-totals', data);
    state.headerTotals = data;
  },
  SET_LOADING_TOTALS(state, data) {
    appSessionStorage.setItem('checkout-cart-totals-ready', !data);
    state.loadingCartTotals = data;
  },
  SET_SHIPPING_METHODS(state, shippingMethods) {
    appSessionStorage.setItem('checkout-cart-estimate-shipping', shippingMethods);
    state.shippingMethods = shippingMethods;
  },
  SHOW_CART_POPUP(state, payload) {
    state.showCartPopup = payload;
  },
  SHOW_PRODUCT_ADDED(state, payload) {
    state.showProductAdded = payload;
  },
  SET_CART_MESSAGE(state, message) {
    state.cartMessage = message;
  },
  SET_MERGING_QUOTE(state, payload) {
    state.mergingQuote = payload;
  },
  ADD_TO_CART_ERROR(state, payload) {
    state.addToCartError = payload;
  },

  SET_DEFAULT_CARRIER_DATA(state, data) {
    state.defaultMethodCode = data['methodCode'];
    state.defaultCarrierCode = data['carrierCode'];
  },
};

const actions = {
  async collectDefaultShippingMethods({rootState, rootGetters, commit}) {
    try {
      const [_billingAddress, _shippingAddress] = rootGetters['CheckoutCheckout/convertAddressData'];
      const {data} = await this.$mageClient.post(`${rootGetters['CheckoutGlobal/getApiPathWithQuoteMask']}/estimate-shipping-methods`, {
        address: _shippingAddress
      });

      if (data.length > 0) {
        commit('SET_DEFAULT_CARRIER_DATA', {
          methodCode: data[0]['method_code'],
          carrierCode: data[0]['carrier_code'],
        });
      }
    } catch (e) {
    }
  },
  async createQuote({rootGetters, commit, dispatch}) {
    try {
      const {data} = await this.$mageClient.post(`${rootGetters['CheckoutGlobal/getApiPath']}`);
      commit('CheckoutGlobal/SET_QUOTE_MASK', data, {root: true});

      await dispatch('fetchQuote');
    } catch (e) {
      //
    }
  },
  async fetchQuote({rootState, rootGetters, commit, dispatch}) {
    try {
      const {data} = await this.$mageClient.get(`${rootGetters['CheckoutGlobal/getApiPathWithQuoteMask']}`);
      if (!data['is_active']) {
        return await dispatch('createQuote');
      }
      await commit('CheckoutGlobal/SET_QUOTE', data, {root: true});

      return rootState['CheckoutGlobal']['quote'];
    } catch (e) {
      await dispatch('createQuote');
    }
  },
  async addProductToQuote({rootState, rootGetters, commit, dispatch}, payload) {
    await commit('SET_LOADING_TOTALS', true);
    await commit('ADD_TO_CART_ERROR', false);
    await dispatch('CheckoutGlobal/getActiveQuote', null, {root: true});
    try {
      const {data} = await this.$mageClient.post(`${rootGetters['CheckoutGlobal/getApiPathWithQuoteMask']}/bulkItems`, {
          cartItems: [
            {
              sku: payload.sku,
              qty: payload.qty,
              quote_id: `${rootGetters['CheckoutGlobal/getQuoteIdentifier']}`,
              product_option: payload.product_option
            },
          ],
        }
      );

      commit('SET_CART_TOTALS', data);
      commit('SET_HEADER_TOTALS', data);
      commit('SHOW_CART_POPUP', true);
      commit('SHOW_PRODUCT_ADDED', true);
      commit('CheckoutGlobal/SET_LAST_ADDED_ITEM', data.extension_attributes.last_added_item, {root: true});
      commit('CheckoutGlobal/SET_QUOTE_ITEMS', data.items, {root: true});
      commit('CheckoutGlobal/SET_QUOTE_ITEM_COUNT', data.items_qty, {root: true});
    } catch (err) {
      if (!err.hasOwnProperty('response')) {
        commit('ADD_TO_CART_ERROR', true);
        console.log(err);
        return;
      }
      if (err.response.status === 404) {
        if (!payload.hasOwnProperty('tries')) {
          payload = {...{tries: 0}, ...payload};
        }

        if (payload.tries >= 3) {
          commit('ADD_TO_CART_ERROR', true);
        }

        if (payload.hasOwnProperty('tries') && payload.tries < 3) {
          payload.tries++;
          await dispatch('CheckoutGlobal/createNewQuote', null, {root: true});
          await dispatch('addProductToQuote', payload);
        }
      } else if (err.response.status === 400) {
        if (err.response.data.hasOwnProperty('message')) {
          commit('SET_CART_MESSAGE', err.response.data.message);
        }

        commit('ADD_TO_CART_ERROR', true);
      }
    }
  },
  async mergeQuote({ rootState, dispatch, commit }) {
    commit('SET_LOADING_TOTALS', true);
    commit('SET_MERGING_QUOTE', true);
    const _oldItemCount = rootState['CheckoutGlobal']['quoteItemCount'];
    const _mergeUrl = `/rest/${rootState['CheckoutGlobal']['storeCode']}/V1/guest-carts/${rootState['CheckoutGlobal']['quoteMask']}`;

    try {
      await dispatch('fetchQuote');
      await this.$mageClient.put(_mergeUrl, {
          customerId: `${rootState['CheckoutGlobal']['customer']['entity_id']}`,
          storeId: `${rootState['CheckoutGlobal']['customer']['store_id']}`
        }
      );
    } catch (e) {
      await this.$mageClient.put(_mergeUrl, {
          customerId: `${rootState['CheckoutGlobal']['customer']['entity_id']}`,
          storeId: `${rootState['CheckoutGlobal']['customer']['store_id']}`
        }
      );
    }
    finally {
      await dispatch('fetchQuote');
      await dispatch('fetchCartTotals', 'cart');
      if (_oldItemCount !== rootState['CheckoutGlobal']['quoteItemCount']) {
        await commit('SET_MERGING_QUOTE_MESSAGE', true);
      }
      await commit('SET_MERGING_QUOTE', false);
    }
  },
  async updateQuoteItemQty({dispatch, rootGetters, commit}, cartItem) {
    commit('SET_LOADING_TOTALS', true);

    return await this.$mageClient.put(`${rootGetters['CheckoutGlobal/getApiPathWithQuoteMask']}/items/${cartItem['item_id']}`, {
      cartItem: {
        sku: cartItem.sku,
        qty: cartItem.qty,
        quote_id: `${rootGetters['CheckoutGlobal/getQuoteIdentifier']}`
      }
    });
  },
  async deleteQuoteItem({rootGetters, dispatch, commit}, cartItemId) {
    commit('SET_LOADING_TOTALS', true);
    await this.$mageClient.delete(`${rootGetters['CheckoutGlobal/getApiPathWithQuoteMask']}/items/${cartItemId}`);

    return await dispatch('fetchCartTotals', 'cart');
  },
  async fetchCartTotals({state, rootGetters, commit, dispatch}, step) {
    commit('SET_LOADING_TOTALS', true);
    const [_billingAddress, _shippingAddress] = rootGetters['CheckoutCheckout/getAddressData'];

    this.dispatch('CheckoutGlobal/reInitializeCustomer', null, {root: true});

    try {
      const {data} = await this.$mageClient.post(`${rootGetters['CheckoutGlobal/getApiPathWithQuoteMask']}/totals-information`, {
        addressInformation: {
          address: (_shippingAddress.hasOwnProperty('country_id') && _shippingAddress['country_id'].length > 0)
          || (_shippingAddress.hasOwnProperty('countryId') && _shippingAddress['countryId'].length > 0)
            ? _shippingAddress
            : {
              prefix: "",
              firstname: "",
              middlename: "",
              lastname: "",
              postcode: "",
              street: ["", "", ""],
              city: "",
              telephone: "",
              company: "",
              country_id: window.Locale.substring(3, 5)?.toUpperCase() || 'NL'
            },
          shipping_method_code: state.defaultMethodCode,
          shipping_carrier_code: state.defaultCarrierCode,
        }
      });

      commit('SET_CART_TOTALS', data);
      commit('SET_HEADER_TOTALS', data);
      commit('CheckoutGlobal/SET_QUOTE_ITEMS', data.items, {root: true});
      commit('CheckoutGlobal/SET_QUOTE_ITEM_COUNT', data.items_qty, {root: true});

      if (step === 'checkout' || step === 'payment') {
        await dispatch('CheckoutPayment/collectPaymentMethods', null, {root: true});
      }

      if (step !== 'cart') {
        const outOfStock = data['items'].filter((item) => {
          return !item['extension_attributes']['product_stock'] || item['extension_attributes']['product_stock'] < item['qty'];
        });
        if (outOfStock.length) {
          window.location.replace('/checkout/cart');
        }
      }

      return data;
    } catch (err) {
      if (err.hasOwnProperty('response')) {
        window.location.replace('/checkout/cart#clear');
        location.reload();
      }
    } finally {
      commit('SET_LOADING_TOTALS', false);
    }
  },
  showCartPopup({state, commit, dispatch}, status) {
    commit('SHOW_CART_POPUP', status);
    if(status && null === state.headerTotals) {
      try {
        dispatch('fetchCartTotals');
      } catch (e) {
        console.log(e);
      }
    }
  },
  showProductAdded({commit}, payload) {
    commit('SHOW_PRODUCT_ADDED', payload);
  },
};

const getters = {
  cartTotals: state => state.cartTotals,
  headerTotals: state => state.headerTotals,
  loadingCartTotals: state => state.loadingCartTotals,
  showCartPopup: state => state.showCartPopup,
  showProductAdded: state => state.showProductAdded,
};

const CheckoutCart = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default CheckoutCart;
