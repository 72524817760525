export default {
    "Accessories": "Zubeh\u00f6r",
    "Account overview": "Konto\u00fcbersicht",
    "Add new address": "Neue Adresse hinzuf\u00fcgen",
    "Add to cart": "In den Warenkorb legen",
    "Add.": "Adresszusatz",
    "Added to cart": "Hinzuf\u00fcgen in den Warenkorb",
    "Adding to cart": "Hinzuf\u00fcgen zum Warenkorb",
    "Addition": "Zusatz",
    "Address": "Adresse",
    "Address not found": "Adresse nicht gefunden",
    "Addresses": "Adressen",
    "All features": "Alle Features",
    "All rights reserved.": "Alle Rechte vorbehalten.",
    "Apply": "Anwenden",
    "Are you an existing customer?": "Bist du bereits Kunde?",
    "Attributes": "Eigenschaften",
    "Available from": "Verf\u00fcgbar ab",
    "Bank": "Bank",
    "Best Use": "Beste Verwendung",
    "Billing address": "Rechnungsadresse",
    "Billing address is different from the address above": "Die Rechnungsadresse unterscheidet sich von der Lieferadresse",
    "Business": "Gesch\u00e4ftskunde",
    "By clicking start payment you agree to our {url}": "Wenn du auf Jetzt Bezahlen klickst, stimmst du unseren {url} zu.",
    "Cancel": "Stornieren",
    "Care": "Pflege",
    "Cart": "Einkaufswagen",
    "Categories": "Kategorien",
    "Category": "Kategorie",
    "Change password": "Kennwort \u00e4ndern",
    "Checkout": "Kasse",
    "Choose a selection...": "W\u00e4hlen Sie eine Auswahl ...",
    "Choose an option": "W\u00e4hle eine Option",
    "Choose size": "Gr\u00f6\u00dfe ausw\u00e4hlen",
    "Choose your {option}": "W\u00e4hlen Sie Ihre {Option}",
    "Choose {option}": "{option} ausw\u00e4hlen",
    "City": "Stadt",
    "Clear all": "Alles l\u00f6schen",
    "Click here to automatically retrieve your address": "Klicken Sie hier, um Ihre Adresse automatisch abzurufen.",
    "Click here to enter the address manually.": "Klicken Sie hier, um die Adresse manuell einzugeben.",
    "Click {url} to continue shopping": "Klicken Sie auf {url}, um weiter einzukaufen.",
    "Close": "Schlie\u00dfen",
    "CoC number": "KOC -Nummer",
    "Color": "Farbe",
    "Company": "Unternehmen",
    "Confirm new password": "Best\u00e4tige neues Passwort",
    "Confirm password": "Best\u00e4tige das Passwort",
    "Contact details": "Kontaktdetails",
    "Contact us": "Kontaktiere uns",
    "Country": "Land",
    "Create account": "Benutzerkonto erstellen",
    "Current password": "Aktuelles Passwort",
    "Date": "Datum",
    "Default billing address": "Standard -Abrechnungsadresse",
    "Default shipping address": "Standardlieferadresse",
    "Description": "Beschreibung",
    "Dimensions": "Abmessungen",
    "Discover our {categoryTitle}": "Entdecken Sie unsere {categoryTitle}",
    "Do you have a promotional code?": "Hast du einen Gutscheincode?",
    "Do you receive our newsletter?": "Erhalten Sie unseren Newsletter?",
    "Edit": "Bearbeiten",
    "Edit address": "Adresse bearbeiten",
    "Edit address details": "Adressdetails bearbeiten",
    "Edit personal settings": "Pers\u00f6nliche Einstellungen bearbeiten",
    "Email address": "E-Mail",
    "Enter a correct value": "Geben Sie einen korrekten Wert ein",
    "Essential details": "Wesentliche Details",
    "Essential items": "Essentielle Dinge",
    "Experience the benefits": "Erleben Sie die Vorteile",
    "Extra Addresses": "Zus\u00e4tzliche Adressen",
    "Failed to add to cart": "Vers\u00e4umt, den Warenkorb hinzuzuf\u00fcgen",
    "Featured": "Ausgew\u00e4hlt",
    "Features": "Ausstattung",
    "Female": "Weiblich",
    "Filter": "Filter",
    "First name": "Vorname",
    "Forgot password": "Passwort vergessen",
    "Forgot password?": "Passwort vergessen?",
    "Free": "Kostenlos",
    "Grand total": "Gesamtsumme",
    "Het wachtwoord heeft minstens %1 tekens nodig. Maak een nieuw wachtwoord en probeer het opnieuw.": "Das Passwort ben\u00f6tigt mindestens %1 Zeichen. Erstellen Sie ein neues Passwort und versuchen Sie es erneut.",
    "Highlights": "Highlights",
    "House no.": "Hausnr.",
    "House number": "Hausnummer",
    "If an account is found with {email}, you will receive an email with a link to reset your password.": "Wenn ein Konto mit: E -Mail gefunden wird, erhalten Sie eine E -Mail mit einem Link zum Zur\u00fccksetzen Ihres Passworts.",
    "If an account with the given email address exists you will receive a password reset email.": "Wenn ein Konto mit der angegebenen E -Mail -Adresse vorhanden ist, erhalten Sie eine E -Mail mit Passwort zur\u00fccksetzen.",
    "Included equipment": "Zubehör",
    "Including items": "Inklusive Artikel",
    "Individual": "Privatperson",
    "Invalid address. Are you sure it is correct?": "Ung\u00fcltige Adresse. Bist du sicher, dass es richtig ist?",
    "Invalid captcha.": "Ung\u00fcltiges Captcha.",
    "Item": "Artikel",
    "Join now": "Jetzt beitreten",
    "Last name": "Nachname",
    "Loading shopping cart items...": "Laden von Einkaufswagenartikeln ...",
    "Log in": "Anmeldung",
    "Login": "Anmeldung",
    "Login here": "Hier anmelden",
    "Logout": "Ausloggen",
    "Male": "M\u00e4nnlich",
    "Material & maintenance": "Material und Wartung",
    "Materials, care & storage": "Materialien, Pflege & Lagerung",
    "Measurements & weight": "Messungen & Gewicht",
    "Minimum van verschillende klassen van tekens in wachtwoord is %1. Soorten tekens: kleine letters, hoofdletters, cijfers en speciale tekens.": "Das Minimum verschiedener Zeichenklassen im Passwort ist %1. Zeichenarten: Kleinbuchstaben, Gro\u00dfbuchstaben, Zahlen und Sonderzeichen.",
    "More of this": "Mehr davon",
    "Mr.": "Herr.",
    "Mrs.": "Frau.",
    "My account": "Mein Konto",
    "My favorites": "Meine Favoriten",
    "My orders": "Meine Bestellungen",
    "My reviews": "Meine Bewertungen",
    "Name": "Name",
    "Name (A-Z)": "Name (a-z)",
    "Name (Z-A)": "Name (Z-A)",
    "New customers": "Neue Kunden",
    "New password": "Neues Kennwort",
    "Newsletter": "Newsletter",
    "Newsletters": "Newsletter",
    "No addresses available": "Keine Adressen verf\u00fcgbar",
    "No default billing address available": "Keine Standard -Abrechnungsadresse verf\u00fcgbar",
    "No default shipping address available": "Keine Standard -Versandadresse verf\u00fcgbar",
    "No posts found": "Keine Eintr\u00e4ge gefunden",
    "No products were found in this category.": "In dieser Kategorie wurden keine Produkte gefunden.",
    "Not in stock": "Nicht lagernd",
    "One or more products are out of stock.": "Ein oder mehrere Produkte sind nicht vorr\u00e4tig.",
    "Order #": "Befehl #",
    "Order {increment}": "Reihenfolge: Inkrement",
    "Order id": "Auftragsnummer",
    "Order placed": "Bestellung abgeschlossen",
    "Order not found": "Nicht gefunden",
    "Ordered items": "Bestellte Artikel",
    "Other series": "Andere Serien",
    "Packaging": "Verpackung",
    "Password": "Passwort",
    "Payment": "Zahlung",
    "Payment method": "Bezahlverfahren",
    "Payment methods": "Zahlungsarten",
    "Personal settings": "Pers\u00f6nliche Einstellungen",
    "Phone number": "Telefonnummer",
    "Please enter a valid code": "Bitte trage einen korrekten Code ein",
    "Postal code": "Postleitzahl",
    "Postal code + house number": "Postcode + Hausnummer",
    "Prefix": "Anrede",
    "Price": "Preis",
    "Price (Ascending)": "Preis (aufsteigend)",
    "Price (Descending)": "Preis (absteigend)",
    "Product has been added to your shopping cart": "Produkt wurde in Ihren Warenkorb hinzugef\u00fcgt",
    "Product size": "Produktgr\u00f6\u00dfe",
    "Product video": "Produktvideo",
    "Quantity": "Menge",
    "Question or remark": "Frage oder Bemerkung",
    "Rate us": "Bewerten Sie uns",
    "Read less": "Lese weniger",
    "Read more": "Weiterlesen",
    "Recent orders": "Letzte Bestellungen",
    "Recent posts": "K\u00fcrzliche Posts",
    "Recently viewed": "Zuletzt angesehen",
    "People are also interested in": "Die Menschen interessieren sich auch f\u00fcr",
    "Related categories": "Verwandte Kategorien",
    "Related products": "Verwandte Produkte",
    "Remove": "Entfernen",
    "Request password": "Kennwort abfragen",
    "Review not found": "Bewertung nicht gefunden",
    "Reviews": "Bewertungen",
    "Rider": "Rider",
    "SKU": "SKU",
    "Save": "Speichern",
    "Save address": "Adresse speichern",
    "Search": "Suchen",
    "Search results": "Suchergebnisse",
    "Search results for &ldquo;{term}&rdquo;": "Suchergebnisse f\u00fcr &ldquo;{term}&rdquo;",
    "Search results for \"{term}\"": "Suchergebnisse f\u00fcr \"{Term}\"",
    "Secure connection": "Sichere Verbindung",
    "Select a country": "W\u00e4hle ein Land",
    "Select payment": "Zahlung ausw\u00e4hlen",
    "Select payment method": "W\u00e4hle deine Zahlungsmethode",
    "Select shipping": "Versand ausw\u00e4hlen",
    "Select shipping method": "Versandmethode ausw\u00e4hlen",
    "Select your address": "W\u00e4hlen Sie Ihre Adresse aus",
    "Send": "Schicken",
    "Ship to": "Ausliefern",
    "Shipping": "Versand",
    "Shipping address": "Lieferadresse",
    "Shipping method": "Versandmethode",
    "Shipping methods are not available right now": "Versandmethoden sind derzeit nicht verf\u00fcgbar",
    "Shipping options will be updated during checkout": "Versandoptionen werden w\u00e4hrend des Checkouts aktualisiert",
    "Show less": "Zeige weniger",
    "Show more": "Zeig mehr",
    "Show more photo\"s": "Zeigen Sie mehr Foto\"s",
    "Showing {productsPerPage}/{totalProducts} products": "Anzeigen {Products Sperpage}/{TotalProducts} Produkte",
    "Showing {totalProductCount} products": "Zeigt {totalProductCount} Produkte",
    "Size Guide": "Gr\u00f6\u00dfentabelle",
    "Sold out": "ausverkauft",
    "Something went wrong while recovering your password": "Bei der Wiederherstellung Ihres Passworts lief etwas schief.",
    "Something went wrong, please try again": "Etwas ist schief gelaufen. Bitte versuche es erneut",
    "Sort by": "Sortiere nach",
    "Specifications": "Spezifikationen",
    "Start payment": "Jetzt Bezahlen",
    "Store locator": "H\u00e4ndlersuche",
    "Street": "Stra\u00dfe",
    "Subscribe to our newsletter": "Abonniere zu unserem Newsletter",
    "Subscribe to the newsletter and never miss a promotion!": "Abonniere den Newsletter und verpasse nie eine Aktion!",
    "Subtotal": "Zwischensumme",
    "Successfully created an account, you can log in with your new credentials": "Sie k\u00f6nnen erfolgreich ein Konto erstellt. Sie k\u00f6nnen sich mit Ihren neuen Anmeldeinformationen anmelden.",
    "Successfully removed address": "Erfolgreich entfernte Adresse",
    "Successfully saved address": "Erfolgreich gespeicherte Adresse",
    "Successfully updated customer": "Erfolgreich aktualisierter Kunde",
    "Successfully updated newsletter subscription": "Erfolgreich aktualisiertes Newsletter -Abonnement",
    "Suitable for": "Passend f\u00fcr",
    "Suited for": "Geeignet f\u00fcr",
    "Summary": "Zusammenfassung",
    "Sustainability": "Nachhaltigkeit",
    "Tags": "Stichworte",
    "Telephone": "Telefon",
    "Terms & conditions": "Allgemeinen Geschäftsbedingungen",
    "Thank you for your purchase!": "Danke für deinen Einkauf!",
    "The account login is incorrect or your account has been temporarily disabled. Wait and try again later.": "Das Kontoanmeldung ist falsch oder Ihr Konto wurde vor\u00fcbergehend deaktiviert. Warten Sie und versuchen Sie es sp\u00e4ter erneut.",
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "Das Kontoanmeldung war falsch oder Ihr Konto ist vor\u00fcbergehend deaktiviert. Bitte warten Sie und versuchen Sie es sp\u00e4ter erneut.",
    "The coupon code isn't valid. Verify the code and try again.": "Der Gutscheincode ist nicht gültig. Bitte bestätige den Code und probiere es nochmal.",
    "There are no available options for this product.": "Es gibt keine verf\u00fcgbaren Optionen f\u00fcr dieses Produkt.",
    "This personal page offers you an overview of the most recent activities. You can, among other things, view orders and update account information. Click on a link below to get started!": "Diese pers\u00f6nliche Seite bietet Ihnen einen \u00dcberblick \u00fcber die neuesten Aktivit\u00e4ten. Sie k\u00f6nnen unter anderem Bestellungen anzeigen und Kontoinformationen aktualisieren. Klicken Sie auf einen Link unten, um loszulegen!",
    "This product is currently sold out": "Dieses Produkt ist derzeit ausverkauft",
    "Title": "Titel",
    "To checkout": "Bezahlen",
    "To shopping cart": "Einkaufswagen",
    "Total": "Gesamt",
    "Unable to remove address": "Adresse nicht entfernen",
    "Unable to save customer": "Kunden kann nicht retten",
    "Unable to update newsletter subscription": "Das Newsletter -Abonnement kann nicht aktualisiert werden",
    "Usage": "Verwendung",
    "VAT number": "Umsatzsteuer-Identifikationsnummer",
    "View details": "Details anzeigen",
    "View less": "Weniger anzeigen",
    "View more": "Mehr sehen",
    "View order": "Reihenfolge anzeigen",
    "View product": "Produkt anzeigen",
    "Volume": "Volumen",
    "We have found the following address": "Wir haben die folgende Adresse gefunden",
    "Welcome {name}": "Willkommen: Name",
    "What are you looking for?": "Wonach suchst du?",
    "Wishlist": "Wunschzettel",
    "Within 15 minutes you will receive an email confirming your order with all the details. If you have questions about the status of your order, please contact customer support.": "Innerhalb von 15 Minuten erhältst du ein E-Mail, in der deine Bestellung mit allen Details bestätigt wird. Wenn du Fragen zum Status deiner Bestellung hast, dann wende dich bitte an unseren Kundenservice.",
    "Write a review": "Eine Rezension schreiben",
    "You are now logged out and will return to our home page within 5 seconds.": "Sie sind jetzt angemeldet und werden innerhalb von 5 Sekunden zu unserer Startseite zur\u00fcckkehren.",
    "You are now signed out": "Sie sind jetzt unterschrieben",
    "You are subscribed to our newsletter": "Sie sind zu unserem Newsletter abonniert",
    "You have no product(s) in your shopping cart.": "Sie haben keine Produkte in Ihrem Einkaufswagen.",
    "You have not added any favorite products yet. Click on the heart next to a product while shopping to save it as your favourite.": "Sie haben noch keine Lieblingsprodukte hinzugef\u00fcgt. Klicken Sie auf das Herz neben einem Produkt, w\u00e4hrend Sie einkaufen, um es als Ihr Favorit zu speichern.",
    "You have not placed any orders yet": "Sie haben noch keine Bestellungen aufgegeben",
    "You have not reviewed any products yet": "Sie haben noch keine Produkte \u00fcberpr\u00fcft",
    "Your address details": "Ihre Adressdetails",
    "Your applied coupon code is {couponCodeInput}.": "Dein verwendeter Gutscheincode ist {couponCodeInput}.",
    "Your details": "Deine Details",
    "Your email": "Deine E-Mail",
    "Your message": "Ihre Nachricht",
    "Your message has been sent.": "Ihre Nachricht wurde gesendet.",
    "Your order has been successfully placed.": "Ihre Bestellung wurde erfolgreich aufgegeben.",
    "Your order id is:": "Deine Bestell-ID ist :",
    "Your password has been updated": "Dein Passwort wurde aktualisiert",
    "here": "Hier",
    "incl. VAT": "inkl. Mehrwertsteuer",
    "name_s": "name_s",
    "out of stock": "ausverkauft",
    "price": "Preis",
    "Alternatives of this product": "Alternativen zu diesem Produkt",
    "ratings": "Bewertungen",
    "subscribe": "abonnieren",
    "successfully logged out": "erfolgreich angemeldet",
    "validation.email": "Bitte geben Sie eine g\u00fcltige E-Mail-Adresse ein",
    "validation.required": "Das ist ein Pflichtfeld",
    "More detailed product images": "Detailliertere Produktbilder",
    "Subscribe to our personalized newsletter for exclusive NOMAD news, offers, and make sure you don't miss a single promotion!": "Abonnieren Sie unseren personalisierten Newsletter f\u00fcr exklusive NOMAD-Neuigkeiten und Angebote und stellen Sie sicher, dass Sie keine einzige Aktion verpassen!",
    "Show alternatives": "Alternativen anzeigen",
    "All images": "Alle Bilder",
    "Learn more about the": "Erfahren Sie mehr \u00fcber die",
    "Customers also bought": "Kunden haben auch gekauft",
    "Suggestions for the ultimate Mistral experience": "Vorschl\u00e4ge f\u00fcr das ultimative Mistral-Erlebnis"
}
