/**
 * Import all polyfills.
 */
import 'url-search-params-polyfill';
import 'custom-event-polyfill';

/**
 * Import all required libraries.
 */
import Vue from 'vue';
import Vuex from 'vuex';
import * as VueGoogleMaps from 'gmap-vue';
import ServiceContainer from '@flashpointbv/solar-service-container';
import Environment from '@flashpointbv/solar-ui-environment';
import VueI18n from 'vue-i18n';
import VueLazyload from 'vue-lazyload';
import {UICoreSticky, UICoreOffCanvas, UICoreBlazeSlider} from '@flashpointbv/solar-ui-core';
import {SolarDataLayer} from '@flashpointbv/solar-datalayer/dist';

SolarDataLayer.init({
    'item_variant': 'color',
});

new UICoreBlazeSlider();
new UICoreSticky().sticky();
new UICoreOffCanvas().offCanvas(true);

Vue.use(VueI18n);
Vue.use(VueLazyload);
Vue.use(Vuex);

Vue.use(VueGoogleMaps, {
    load: {
        key: window.Config['google_api_key'],
        libraries: 'places',
        region: 'NL',
        language: 'nl'
    }
});

/**
 * Set important environment variables
 */
Environment().set('locale', window.Locale);
Environment().set('locale_short', window.Locale.substr(0, 2).toLowerCase());
for (let key in window.Config) Environment().set(key, window.Config[key]);

/*
* Create store instance and register the store modules
 */
import {CatalogCategory, CatalogProduct, CatalogBundleProduct, CatalogGroupedProduct, CatalogConfigurableProduct} from '@flashpointbv/solar-ui-catalog/src';
import {CheckoutGlobal, CheckoutCheckout, CheckoutShipping, CheckoutPayment, CheckoutTotals} from '@flashpointbv/solar-ui-checkout/dist';
import CatalogCategoryReviews from '../../default/js/stores/catalog/category/reviews';
import CheckoutCart from '../../default/js/stores/checkout';

const store = new Vuex.Store({
    strict: false,
});

store.registerModule('CatalogCategory', CatalogCategory);

store.registerModule('CatalogProduct', CatalogProduct);
store.registerModule('CatalogBundleProduct', CatalogBundleProduct);
store.registerModule('CatalogGroupedProduct', CatalogGroupedProduct);
store.registerModule('CatalogConfigurableProduct', CatalogConfigurableProduct);

store.registerModule('CheckoutGlobal', CheckoutGlobal);
store.registerModule('CheckoutCart', CheckoutCart);
store.registerModule('CheckoutCheckout', CheckoutCheckout);
store.registerModule('CheckoutShipping', CheckoutShipping);
store.registerModule('CheckoutPayment', CheckoutPayment);
store.registerModule('CheckoutTotals', CheckoutTotals);

store.registerModule('CatalogCategoryReviews', CatalogCategoryReviews);

/**
 * Require application scripts.
 */
require('../../default/js/prototypes');
require('../../default/js/core');
require('./off-canvas');

/**
 * Import all required styles.
 */
import '../scss/app.scss';

/**
 * Register Vue components.
 */
ServiceContainer().addComponents([
    {key: 'footer-subscribe', concrete: () => import('./components/FooterSubscribe.vue')},
    {key: 'block-slider', concrete: () => import('../../default/js/components/blocks/BlockSlider.vue')},
    {key: 'home-list', concrete: () => import('../../default/js/components/HomeList.vue')},
    {key: 'header-cart', concrete: require('./components/HeaderCart').default},

    //Homepage & Landingpage Builder
    {key: 'home-slider', concrete: () => import('../../default/js/components/home/HomeSlider.vue')},
    {key: 'blocks-four-column-banners', concrete: () => import('../../default/js/components/blocks/FourColumnBanners.vue')},
    {key: 'blocks-usp-image-blocks', concrete: () => import('../../default/js/components/blocks/UspImageBlocks.vue')},
    {key: 'blocks-team-riders', concrete: () => import('../../default/js/components/blocks/TeamRiders.vue')},
    {key: 'blocks-innovations-block', concrete: () => import('../../default/js/components/blocks/InnovationBlock.vue')},
    {key: 'blocks-three-column-slider', concrete: () => import('../../default/js/components/blocks/ThreeColumnSlider.vue')},
    {key: 'blocks-blog-block', concrete: () => import('../../default/js/components/blocks/BlogBlock.vue')},
    {key: 'blocks-category-products-block', concrete: () => import('../../default/js/components/blocks/CategoryProductsBlock.vue')},

    //Autocomplete
    {key: 'search-autocomplete', concrete: require('./components/search/SearchForm').default},
    {key: 'search-autocomplete-list-item', concrete: () => import('../../default/js/components/search/AutocompleteListItem.vue')},

    //Catalog
    {key: 'catalog-category-view', concrete: () => import('../../default/js/./views/catalog/CatalogCategoryView.vue')},
    {key: 'catalog-search-view', concrete: () => import('../../default/js/./views/catalog/CatalogSearchView.vue')},

    {key: 'catalog-product-list', concrete: () => import('./components/catalog/ProductList.vue')},
    {key: 'catalog-product-list-add-to-cart', concrete: () => import('./components/catalog/ProductListAddToCart.vue')},
    {key: 'catalog-product-list-toolbar', concrete: () => import('./components/catalog/ProductListToolbar.vue')},
    {key: 'catalog-product-filter-list', concrete: () => import('./components/catalog/ProductFilterList.vue')},
    {key: 'catalog-product-list-item', concrete: () => import('./components/catalog/ProductListItem.vue')},
    {key: 'catalog-product-list-toolbar-sort-select', concrete: () => import('./components/catalog/ProductListToolbarSortSelect.vue')},
    {key: 'catalog-product-list-toolbar-paginator', concrete: () => import('../../default/js/components/catalog/ProductListToolbarPaginator.vue')},
    {key: 'catalog-related-items-slider', concrete: () => import('./components/catalog/RelatedItemsSlider.vue')},

    //Landing page
    {key: 'catalog-landing-usp-slider', concrete: () => import('../../default/js/components/catalog/landing/UspSlider.vue')},
    {key: 'catalog-landing-filter-slider', concrete: () => import('../../default/js/components/catalog/landing/FilterSlider.vue')},

    //Catalog Filters
    {key: 'catalog-product-list-attribute-filter', concrete: () => import('./components/catalog/filter/AttributeFilter.vue')},
    {key: 'catalog-product-list-swatch-filter', concrete: () => import('./components/catalog/filter/SwatchFilter.vue')},
    {key: 'catalog-product-list-price-filter', concrete: () => import('./components/catalog/filter/PriceFilter.vue')},

    //Catalog Product
    {key: 'catalog-product-configuration', concrete: () => import('./components/catalog/product/ProductConfiguration.vue')},
    {key: 'catalog-product-price', concrete: () => import('./components/catalog/product/ProductPrice.vue')},
    {key: 'catalog-product-media', concrete: () => import('./components/catalog/product/ProductMedia.vue')},
    {key: 'catalog-product-media-panzoom', concrete: () => import('./components/catalog/product/ProductMediaPanzoom.vue')},
    {key: 'catalog-product-simple', concrete: () => import('../../default/js/components/catalog/product/simple/ProductTypeSimple.vue')},
    {key: 'catalog-product-configurable', concrete: () => import('./components/catalog/product/configurable/ProductTypeConfigurable.vue')},
    {key: 'catalog-product-configurable-select', concrete: () => import('./components/catalog/product/configurable/type/OptionSelect.vue')},
    {key: 'catalog-product-configurable-swatch', concrete: () => import('./components/catalog/product/configurable/type/OptionSwatch.vue')},
    {key: 'catalog-product-bundle', concrete: () => import('../../default/js/components/catalog/product/bundle/ProductTypeBundle.vue')},
    {key: 'catalog-product-bundle-select', concrete: () => import('../../default/js/components/catalog/product/bundle/type/BundleSelect.vue')},
    {key: 'catalog-product-bundle-radio', concrete: () => import('../../default/js/components/catalog/product/bundle/type/BundleRadio.vue')},
    {key: 'catalog-product-bundle-checkbox', concrete: () => import('../../default/js/components/catalog/product/bundle/type/BundleCheckbox.vue')},
    {key: 'catalog-product-grouped', concrete: () => import('../../default/js/components/catalog/product/grouped/ProductTypeGrouped.vue')},
    {key: 'catalog-product-grouped-product', concrete: () => import('../../default/js/components/catalog/product/grouped/ProductTypeGroupedProduct.vue')},
    {key: 'catalog-product-sticky-add-to-cart', concrete: () => import('../../default/js/components/catalog/product/StickyAddToCart.vue')},
    {key: 'catalog-product-add-to-cart', concrete: () => import('./components/catalog/product/ProductAddToCart.vue')},
    {key: 'catalog-product-key-features', concrete: () => import('./components/catalog/product/KeyFeatures.vue')},
    {key: 'catalog-last-viewed-items-slider', concrete: () => import('./components/catalog/product/LastViewedItemsSlider.vue')},
    {key: 'catalog-product-selected-configuration', concrete: () => import('./components/catalog/product/SelectedConfiguration.vue')},
    {key: 'product-related', concrete: () => import('./components/catalog/ProductRelated.vue')},
    {key: 'sold-out-label', concrete: () => import('./components/catalog/product/ProductSoldOut.vue')},
    {key: 'product-media-slider', concrete: () => import('./components/catalog/ProductMediaSlider.vue')},
    {key: 'product-media-info', concrete: () => import('./components/catalog/ProductMediaInfo.vue')},

    //Cart
    {key: 'checkout-cart', concrete: () => import('./components/checkout/cart/Cart.vue')},
    {key: 'checkout-coupon-form', concrete: () => import('../../default/js/components/checkout/coupon/CouponForm.vue')},
    {key: 'checkout-cart-items', concrete: () => import('../../default/js/components/checkout/cart/CartItems.vue')},
    {key: 'checkout-cart-items-list', concrete: () => import('./components/checkout/cart/CartItemsList.vue')},
    {key: 'checkout-cart-totals', concrete: () => import('../../default/js/components/checkout/cart/CartTotals.vue')},
    {key: 'checkout-cart-crosssells', concrete: () => import('./components/checkout/cart/ProductCrosssellList.vue')},

    //Checkout
    {key: 'checkout-checkout', concrete: () => import('../../default/js/components/checkout/checkout/Checkout.vue')},
    {key: 'checkout-newsletter', concrete: () => import('../../default/js/components/checkout/checkout/CheckoutNewsletter.vue')},

    {key: 'checkout-address-detail', concrete: () => import('../../default/js/components/checkout/checkout/step/AddressDetail.vue')},
    {key: 'checkout-address-shipping', concrete: () => import('../../default/js/components/checkout/checkout/address/ShippingAddress.vue')},
    {key: 'checkout-address-billing', concrete: () => import('../../default/js/components/checkout/checkout/address/BillingAddress.vue')},
    {key: 'checkout-address-login', concrete: () => import('../../default/js/components/checkout/checkout/address/CheckoutLogin.vue')},
    {key: 'checkout-address-login-form', concrete: () => import('../../default/js/components/checkout/checkout/address/CheckoutLoginForm.vue')},
    {key: 'checkout-address-autocomplete', concrete: () => import('../../default/js/components/checkout/checkout/address/input/AddressAutocomplete.vue')},
    {key: 'checkout-address-form', concrete: () => import('../../default/js/components/checkout/checkout/address/input/AddressForm.vue')},
    {key: 'checkout-address-company-input', concrete: () => import('../../default/js/components/checkout/checkout/address/input/AddressCompanyInput.vue')},
    {key: 'checkout-address-select', concrete: () => import('../../default/js/components/checkout/checkout/address/input/AddressSelect.vue')},
    {key: 'checkout-address-option', concrete: () => import('../../default/js/components/checkout/checkout/address/input/AddressOption.vue')},

    {key: 'checkout-shipping-detail', concrete: () => import('../../default/js/components/checkout/checkout/step/ShippingDetail.vue')},
    {key: 'checkout-shipping-methods-default', concrete: () => import('../../default/js/components/checkout/checkout/shipping/methods/Default.vue')},

    {key: 'checkout-payment-detail', concrete: () => import('../../default/js/components/checkout/checkout/step/PaymentDetail.vue')},
    {key: 'checkout-payment-methods-default', concrete: () => import('../../default/js/components/checkout/checkout/payment/methods/Default.vue')},

    {key: 'checkout-summary', concrete: () => import('../../default/js/components/checkout/checkout/summary/Summary.vue')},
    {key: 'checkout-totals', concrete: () => import('../../default/js/components/checkout/checkout/summary/CheckoutTotals.vue')},
    {key: 'success-datalayer', concrete: () => import('../../default/js/components/checkout/SuccessDatalayer.vue')},

    // Customer
    {key: 'customer-address-autocomplete', concrete: () => import('../../default/js/components/customer/AddressAutocomplete.vue')},

    //Sliders and swipers
    {key: 'swiper', concrete: () => import('../../default/js/shaked-exports/swiper/swiper')},
    {key: 'swiper-slide', concrete: () => import('../../default/js/shaked-exports/swiper/swiper-slide')},

    //Rest
    {key: 'store-locator', concrete: () => import('../../default/js/components/cms/StoreLocator.vue')},

    //Lazy load components
    {key: 'lazy-load', concrete: () => import('../../default/js/components/LazyLoad.vue')},
]);

import nl_NL from '../../default/js/i18n/nl_NL';
import en_GB from '../../default/js/i18n/en_GB';
import de_DE from '../../default/js/i18n/de_DE';
import es_ES from '../../default/js/i18n/es_ES';
import fr_FR from '../../default/js/i18n/fr_FR';

import {localize} from 'vee-validate';
import vee_en_GB from 'vee-validate/dist/locale/en.json';
import vee_nl_NL from 'vee-validate/dist/locale/nl.json';
import vee_de_DE from 'vee-validate/dist/locale/de.json';
import vee_es_ES from 'vee-validate/dist/locale/es.json';
import vee_fr_FR from 'vee-validate/dist/locale/fr.json';

localize({vee_en_GB, vee_nl_NL, vee_de_DE, vee_es_ES, vee_fr_FR});
localize(`vee_${window.Locale}`);

const messages = {
    nl_NL,
    en_GB,
    de_DE,
    es_ES,
    fr_FR
};

const i18n = new VueI18n({
    locale: window.Locale,
    fallbackLocale: 'en_GB',
    formatFallbackMessages: true,
    silentTranslationWarn: true,
    messages
});

new Vue({
    store,
    i18n,
    components: ServiceContainer().getComponents(),
    data: {
        csrfToken: window.csrfToken
    },
    async created() {
        await this.$store.dispatch('CheckoutGlobal/initialize');
        await this.$store.commit('CheckoutCart/SET_DEFAULT_CARRIER_DATA', {
            methodCode: window.Config['default_method_code'],
            carrierCode: window.Config['default_carrier_code'],
        });

        this.$eventBus.$on('item-rendered', () => {
            const catalogCategoryProductPlaceholders = document.querySelector('.category__placeholder');
            if (catalogCategoryProductPlaceholders) {
                (catalogCategoryProductPlaceholders as HTMLElement).style.display = 'none';
            }
        });
    }
}).$mount('#solar-app');
