export default {
    "Accessories": "Accessories",
    "Account overview": "Account overview",
    "Add new address": "Add new address",
    "Add to cart": "Add to cart",
    "Add.": "Add.",
    "Added to cart": "Added to cart",
    "Adding to cart": "Adding to cart",
    "Addition": "Addition",
    "Address": "Address",
    "Address not found": "Address not found",
    "Addresses": "Addresses",
    "All features": "All features",
    "All rights reserved.": "All rights reserved.",
    "Apply": "Apply",
    "Are you an existing customer?": "Are you an existing customer?",
    "Attributes": "Attributes",
    "Available from": "Available from",
    "Bank": "Bank",
    "Best Use": "Best Use",
    "Billing address": "Billing address",
    "Billing address is different from the address above": "Billing address is different from the address above",
    "Business": "Business",
    "By clicking start payment you agree to our {url}": "By clicking start payment you agree to our {url}",
    "Cancel": "Cancel",
    "Care": "Care",
    "Cart": "Cart",
    "Categories": "Categories",
    "Category": "Category",
    "Change password": "Change password",
    "Checkout": "Checkout",
    "Choose a selection...": "Choose a selection...",
    "Choose an option": "Choose an option",
    "Choose size": "Choose size",
    "Choose your {option}": "Choose your {option}",
    "Choose {option}": "Choose {option}",
    "City": "City",
    "Clear all": "Clear all",
    "Click here to automatically retrieve your address": "Click here to automatically retrieve your address",
    "Click here to enter the address manually.": "Click here to enter the address manually.",
    "Click {url} to continue shopping": "Click {url} to continue shopping",
    "Close": "Close",
    "CoC number": "CoC number",
    "Color": "Color",
    "Company": "Company",
    "Confirm new password": "Confirm new password",
    "Confirm password": "Confirm password",
    "Contact details": "Contact details",
    "Contact us": "Contact us",
    "Country": "Country",
    "Create account": "Create account",
    "Current password": "Current password",
    "Date": "Date",
    "Default billing address": "Default billing address",
    "Default shipping address": "Default shipping address",
    "Description": "Description",
    "Dimensions": "Dimensions",
    "Discount products": "Discount products",
    "Do you have a promotional code?": "Do you have a promotional code?",
    "Do you receive our newsletter?": "Do you receive our newsletter?",
    "Edit": "Edit",
    "Edit address": "Edit address",
    "Edit address details": "Edit address details",
    "Edit personal settings": "Edit personal settings",
    "Email address": "Email address",
    "Enter a correct value": "Enter a correct value",
    "Essential details": "Essential details",
    "Experience the benefits": "Experience the benefits",
    "Extra Addresses": "Extra Addresses",
    "Failed to add to cart": "Failed to add to cart",
    "Featured": "Featured",
    "Features": "Features",
    "Female": "Female",
    "Filter": "Filter",
    "First name": "First name",
    "Forgot password": "Forgot password",
    "Forgot password?": "Forgot password?",
    "Free": "Free",
    "Grand total": "Grand total",
    "Het wachtwoord heeft minstens %1 tekens nodig. Maak een nieuw wachtwoord en probeer het opnieuw.": "Het wachtwoord heeft minstens %1 tekens nodig. Maak een nieuw wachtwoord en probeer het opnieuw.",
    "Highlights": "Highlights",
    "House no.": "House no.",
    "House number": "House number",
    "If an account is found with {email}, you will receive an email with a link to reset your password.": "If an account is found with {email}, you will receive an email with a link to reset your password.",
    "If an account with the given email address exists you will receive a password reset email.": "If an account with the given email address exists you will receive a password reset email.",
    "Included equipment": "Included equipment",
    "Including items": "Including items",
    "Individual": "Individual",
    "Invalid address. Are you sure it is correct?": "Invalid address. Are you sure it is correct?",
    "Invalid captcha.": "Invalid captcha.",
    "Item": "Item",
    "Join now": "Join now",
    "Last name": "Last name",
    "Loading shopping cart items...": "Loading shopping cart items...",
    "Log in": "Log in",
    "Login": "Login",
    "Login here": "Login here",
    "Logout": "Logout",
    "Male": "Male",
    "Material": "Material",
    "Materials, care & storage": "Materials, care & storage",
    "Minimum van verschillende klassen van tekens in wachtwoord is %1. Soorten tekens: kleine letters, hoofdletters, cijfers en speciale tekens.": "Minimum van verschillende klassen van tekens in wachtwoord is %1. Soorten tekens: kleine letters, hoofdletters, cijfers en speciale tekens.",
    "More of this": "More of this",
    "Mr.": "Mr.",
    "Mrs.": "Mrs.",
    "My account": "My account",
    "My favorites": "My favorites",
    "My orders": "My orders",
    "My reviews": "My reviews",
    "Name": "Name",
    "Name (A-Z)": "Name (A-Z)",
    "Name (Z-A)": "Name (Z-A)",
    "New customers": "New customers",
    "New password": "New password",
    "Newsletter": "Newsletter",
    "Newsletters": "Newsletters",
    "No addresses available": "No addresses available",
    "No default billing address available": "No default billing address available",
    "No default shipping address available": "No default shipping address available",
    "No posts found": "No posts found",
    "No products were found in this category.": "No products were found in this category.",
    "Not in stock": "Not in stock",
    "One or more products are out of stock.": "One or more products are out of stock.",
    "Order #": "Order #",
    "Order {increment}": "Order {increment}",
    "Order id": "Order id",
    "Order not found": "Order not found",
    "Ordered items": "Ordered items",
    "Other series": "Other series",
    "Packaging": "Packaging",
    "Password": "Password",
    "Payment method": "Payment method",
    "Payment methods": "Payment methods",
    "Personal settings": "Personal settings",
    "Phone number": "Phone number",
    "Please enter a valid code": "Please enter a valid code",
    "Postal code": "Postal code",
    "Postal code + house number": "Postal code + house number",
    "Prefix": "Prefix",
    "Price": "Price",
    "Price (Ascending)": "Price (Ascending)",
    "Price (Descending)": "Price (Descending)",
    "Product has been added to your shopping cart": "Product has been added to your shopping cart",
    "Product size": "Product size",
    "Product video": "Product video",
    "Quantity": "Quantity",
    "Question or remark": "Question or remark",
    "Rate us": "Rate us",
    "Read less": "Read less",
    "Read more": "Read more",
    "Recent orders": "Recent orders",
    "Recent posts": "Recent posts",
    "Recently viewed": "Recently viewed",
    "People are also interested in": "People are also interested in",
    "Related categories": "Related categories",
    "Related products": "Related products",
    "Remove": "Remove",
    "Request password": "Request password",
    "Review not found": "Review not found",
    "Reviews": "Reviews",
    "Rider": "Rider",
    "SKU": "SKU",
    "Save": "Save",
    "Save address": "Save address",
    "Search": "B\u00fasqueda",
    "Search results": "Search results",
    "Search results &ldquo;": "Search results &ldquo;",
    "Search results for &ldquo;{term}&rdquo;": "Search results for &ldquo;{term}&rdquo;",
    "Search results for \"{term}\"": "Search results for \"{term}\"",
    "Secure connection": "Secure connection",
    "Select a country": "Select a country",
    "Select payment": "Select payment",
    "Select payment method": "Select payment method",
    "Select shipping": "Select shipping",
    "Select shipping method": "Select shipping method",
    "Select your address": "Select your address",
    "Send": "Send",
    "Ship to": "Ship to",
    "Shipping": "Shipping",
    "Shipping address": "Shipping address",
    "Shipping method": "Shipping method",
    "Shipping methods are not available right now": "Shipping methods are not available right now",
    "Shipping options will be updated during checkout": "Shipping options will be updated during checkout",
    "Show less": "Show less",
    "Show more": "Show more",
    "Show more photo\"s": "Show more photo\"s",
    "Showing {productsPerPage}/{totalProducts} products": "Showing {productsPerPage}/{totalProducts} products",
    "Showing {totalProductCount} products": "Showing {totalProductCount} products",
    "Size Guide": "Size Guide",
    "Sold out": "Sold out",
    "Something went wrong while recovering your password": "Something went wrong while recovering your password",
    "Something went wrong, please try again": "Something went wrong, please try again",
    "Sort by": "Sort by",
    "Specifications": "Specifications",
    "Start payment": "Start payment",
    "Store locator": "Store locator",
    "Street": "Street",
    "Subscribe to our newsletter": "Subscribe to our newsletter",
    "Subscribe to the newsletter and never miss a promotion!": "Subscribe to the newsletter and never miss a promotion!",
    "Subtotal": "Subtotal",
    "Successfully created an account, you can log in with your new credentials": "Successfully created an account, you can log in with your new credentials",
    "Successfully removed address": "Successfully removed address",
    "Successfully saved address": "Successfully saved address",
    "Successfully updated customer": "Successfully updated customer",
    "Successfully updated newsletter subscription": "Successfully updated newsletter subscription",
    "Suitable for": "Suitable for",
    "Suited for": "Suited for",
    "Summary": "Summary",
    "Sustainability": "Sustainability",
    "Tags": "Tags",
    "Telephone": "Telephone",
    "Terms & conditions": "Terms & conditions",
    "Thank you for your purchase!": "Thank you for your purchase!",
    "The account login is incorrect or your account has been temporarily disabled. Wait and try again later.": "The account login is incorrect or your account has been temporarily disabled. Wait and try again later.",
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.",
    "There are no available options for this product.": "There are no available options for this product.",
    "This personal page offers you an overview of the most recent activities. You can, among other things, view orders and update account information. Click on a link below to get started!": "This personal page offers you an overview of the most recent activities. You can, among other things, view orders and update account information. Click on a link below to get started!",
    "This product is currently sold out": "This product is currently sold out",
    "To checkout": "To checkout",
    "To shopping cart": "To shopping cart",
    "Total": "Total",
    "Unable to remove address": "Unable to remove address",
    "Unable to save customer": "Unable to save customer",
    "Unable to update newsletter subscription": "Unable to update newsletter subscription",
    "Usage": "Usage",
    "VAT number": "VAT number",
    "View details": "View details",
    "View more {categoryTitle}": "View more {categoryTitle}",
    "View order": "View order",
    "View product": "View product",
    "Volume": "Volume",
    "We have found the following address": "We have found the following address",
    "Welcome {name}": "Welcome {name}",
    "What are you looking for?": "What are you looking for?",
    "Wishlist": "Wishlist",
    "Within 15 minutes you will receive an email confirming your order with all the details. If you have questions about the status of your order, please contact customer support.": "Within 15 minutes you will receive an email confirming your order with all the details. If you have questions about the status of your order, please contact customer support.",
    "Write a review": "Write a review",
    "You are now logged out and will return to our home page within 5 seconds.": "You are now logged out and will return to our home page within 5 seconds.",
    "You are now signed out": "You are now signed out",
    "You are subscribed to our newsletter": "You are subscribed to our newsletter",
    "You have no product(s) in your shopping cart.": "You have no product(s) in your shopping cart.",
    "You have not added any favorite products yet. Click on the heart next to a product while shopping to save it as your favourite.": "You have not added any favorite products yet. Click on the heart next to a product while shopping to save it as your favourite.",
    "You have not placed any orders yet": "You have not placed any orders yet",
    "You have not reviewed any products yet": "You have not reviewed any products yet",
    "Your address details": "Your address details",
    "Your applied coupon code is {couponCodeInput}.": "Your applied coupon code is {couponCodeInput}.",
    "Your details": "Your details",
    "Your email": "Your email",
    "Your message": "Your message",
    "Your message has been sent.": "Your message has been sent.",
    "Your order has been successfully placed.": "Your order has been successfully placed.",
    "Your order id is:": "Your order id is:",
    "Your password has been updated": "Your password has been updated",
    "here": "here",
    "incl. VAT": "incl. VAT",
    "name_s": "name_s",
    "out of stock": "out of stock",
    "price": "price",
    "Alternatives of this product": "Alternativas a este producto",
    "ratings": "ratings",
    "subscribe": "subscribe",
    "successfully logged out": "successfully logged out",
    "validation.email": "validation.email",
    "validation.required": "validation.required",
    "More detailed product images": "Im\u00e1genes m\u00e1s detalladas del producto",
    "Subscribe to our personalized newsletter for exclusive NOMAD news, offers, and make sure you don't miss a single promotion!": "Suscr\u00edbete a nuestro bolet\u00edn personalizado para recibir noticias y ofertas exclusivas de NOMAD y aseg\u00farate de no perderte ni una sola promoci\u00f3n.",
    "sold out": "agotado",
    "Show alternatives": "Mostrar alternativas",
    "All images": "Todas las im\u00e1genes",
    "Learn more about the": "M\u00e1s informaci\u00f3n sobre el",
    "Customers also bought": "Los clientes tambi\u00e9n compraron",
    "Suggestions for the ultimate Mistral experience": "Sugerencias para la experiencia Mistral definitiva"
}