export default {
    "Accessories": "Accessoires",
    "Account overview": "Aper\u00e7u du compte",
    "Add new address": "Ajouter une nouvelle adresse",
    "Add to cart": "Ajouter au panier",
    "Add.": "Ajouter.",
    "Added to cart": "Ajout\u00e9 au panier",
    "Adding to cart": "Ajout au panier",
    "Addition": "Addition",
    "Address": "Adresse",
    "Address not found": "Adresse non trouv\u00e9e",
    "Addresses": "Adresses",
    "All features": "Toutes les fonctionnalit\u00e9s",
    "All rights reserved.": "Tous droits r\u00e9serv\u00e9s.",
    "Apply": "Appliquer",
    "Are you an existing customer?": "\u00cates-vous un client existant ?",
    "Attributes": "Attributs",
    "Available from": "Disponible \u00e0 partir de",
    "Bank": "Banque",
    "Best Use": "Meilleure utilisation",
    "Billing address": "Adresse de facturation",
    "Billing address is different from the address above": "L'adresse de facturation est diff\u00e9rente de l'adresse ci-dessus",
    "Business": "Entreprise",
    "By clicking start payment you agree to our {url}": "En cliquant sur commencer le paiement, vous acceptez notre {url}",
    "Cancel": "Annuler",
    "Care": "Entretien",
    "Cart": "Panier",
    "Categories": "Cat\u00e9gories",
    "Category": "Cat\u00e9gorie",
    "Change password": "Changer le mot de passe",
    "Checkout": "A la caisse",
    "Choose a selection...": "Choisissez une s\u00e9lection...",
    "Choose an option": "Choisissez une option",
    "Choose size": "Choisissez la taille",
    "Choose your {option}": "Choisissez votre {option}",
    "Choose {option}": "Choisir la {option}",
    "City": "Ville",
    "Clear all": "Tout effacer",
    "Click here to automatically retrieve your address": "Cliquez ici pour r\u00e9cup\u00e9rer automatiquement votre adresse",
    "Click here to enter the address manually.": "Cliquez ici pour entrer l'adresse manuellement.",
    "Click {url} to continue shopping": "Cliquez {url} pour continuer vos achats",
    "Close": "Fermer",
    "CoC number": "Num\u00e9ro de la chambre de commerce",
    "Color": "Couleur",
    "Company": "Entreprise",
    "Confirm new password": "Confirmer le nouveau mot de passe",
    "Confirm password": "Confirmer le mot de passe",
    "Contact details": "D\u00e9tails du contact",
    "Contact us": "Contactez-nous",
    "Country": "Pays",
    "Create account": "Cr\u00e9er un compte",
    "Current password": "Mot de passe actuel",
    "Date": "Date",
    "Default billing address": "Adresse de facturation par d\u00e9faut",
    "Default shipping address": "Adresse de livraison par d\u00e9faut",
    "Description": "Description",
    "Dimensions": "Dimensions",
    "Discover our {categoryTitle}": "Découvre notre {categoryTitle}",
    "Do you have a promotional code?": "Avez-vous un code promotionnel ?",
    "Do you receive our newsletter?": "Recevez-vous notre newsletter ?",
    "Edit": "Modifier",
    "Edit address": "Modifier l'adresse",
    "Edit address details": "Modifier les d\u00e9tails de l'adresse",
    "Edit personal settings": "Modifier les param\u00e8tres personnels",
    "Email address": "Adresse e-mail",
    "Enter a correct value": "Entrez une valeur correcte",
    "Essential details": "D\u00e9tails essentiels",
    "Essential items": "Articles essentiels",
    "Experience the benefits": "Profitez des avantages",
    "Extra Addresses": "Adresses suppl\u00e9mentaires",
    "Failed to add to cart": "\u00c9chec de l'ajout au panier",
    "Featured": "En vedette",
    "Features": "Caract\u00e9ristiques",
    "Female": "Femme",
    "Filter": "Filtrer",
    "First name": "Pr\u00e9nom",
    "Forgot password": "Mot de passe oubli\u00e9",
    "Forgot password?": "Mot de passe oubli\u00e9 ?",
    "Free": "Gratuit",
    "Grand total": "Total g\u00e9n\u00e9ral",
    "Het wachtwoord heeft minstens %1 tekens nodig. Maak een nieuw wachtwoord en probeer het opnieuw.": "Le mot de passe doit contenir au moins %1 caract\u00e8res. Cr\u00e9ez un nouveau mot de passe et r\u00e9essayez.",
    "Highlights": "Points forts",
    "House no.": "N\u00b0 de maison",
    "House number": "Num\u00e9ro de maison",
    "If an account is found with {email}, you will receive an email with a link to reset your password.": "Si un compte est trouv\u00e9 avec {email}, vous recevrez un e-mail avec un lien pour r\u00e9initialiser votre mot de passe.",
    "If an account with the given email address exists you will receive a password reset email.": "Si un compte avec l'adresse e-mail donn\u00e9e existe, vous recevrez un e-mail de r\u00e9initialisation du mot de passe.",
    "Included equipment": "\u00c9quipement inclus",
    "Including items": "Articles inclus",
    "Individual": "Individuel",
    "Invalid address. Are you sure it is correct?": "Adresse invalide. \u00cates-vous s\u00fbr qu'elle est correcte ?",
    "Invalid captcha.": "Captcha invalide.",
    "Item": "Article",
    "Join now": "Rejoignez-nous maintenant",
    "Last name": "Nom de famille",
    "Loading shopping cart items...": "Chargement des articles du panier...",
    "Log in": "Se connecter",
    "Login": "Connexion",
    "Login here": "Connectez-vous ici",
    "Logout": "D\u00e9connexion",
    "Male": "Homme",
    "Material & maintenance": "Mat\u00e9riel & entretien",
    "Materials, care & storage": "Matériau, entretien & stockage",
    "Measurements & weight": "Mesures & poids",
    "Minimum van verschillende klassen van tekens in wachtwoord is %1. Soorten tekens: kleine letters, hoofdletters, cijfers en speciale tekens.": "Le minimum de diff\u00e9rentes classes de caract\u00e8res dans le mot de passe est de %1. Types de caract\u00e8res : minuscules, majuscules, chiffres et caract\u00e8res sp\u00e9ciaux.",
    "More of this": "Plus de cela",
    "Mr.": "M.",
    "Mrs.": "Mme",
    "My account": "Mon compte",
    "My favorites": "Mes favoris",
    "My orders": "Mes commandes",
    "My reviews": "Mes avis",
    "Name": "Nom",
    "Name (A-Z)": "Nom (A-Z)",
    "Name (Z-A)": "Nom (Z-A)",
    "New customers": "Nouveaux clients",
    "New password": "Nouveau mot de passe",
    "Newsletter": "Bulletin",
    "Newsletters": "Bulletins",
    "No addresses available": "Aucune adresse disponible",
    "No default billing address available": "Aucune adresse de facturation par d\u00e9faut disponible",
    "No default shipping address available": "Aucune adresse de livraison par d\u00e9faut disponible",
    "No posts found": "Aucun post trouv\u00e9",
    "No products were found in this category.": "Aucun produit trouv\u00e9 dans cette cat\u00e9gorie.",
    "Not in stock": "En rupture de stock",
    "One or more products are out of stock.": "Un ou plusieurs produits sont en rupture de stock.",
    "Order #": "Commande #",
    "Order {increment}": "Commande {increment}",
    "Order id": "ID de commande",
    "Order not found": "Commande non trouv\u00e9e",
    "Order placed": "Commande pass\u00e9e",
    "Ordered items": "Articles command\u00e9s",
    "Other series": "Autres s\u00e9ries",
    "Packaging": "Emballage",
    "Password": "Mot de passe",
    "Payment method": "M\u00e9thode de paiement",
    "Payment methods": "M\u00e9thodes de paiement",
    "Payment": "Paiement",
    "Personal settings": "Param\u00e8tres personnels",
    "Phone number": "Num\u00e9ro de t\u00e9l\u00e9phone",
    "Please enter a valid code": "Veuillez entrer un code valide",
    "Postal code": "Code postal",
    "Postal code + house number": "Code postal + num\u00e9ro de maison",
    "Prefix": "Titre",
    "Price": "Prix",
    "Price (Ascending)": "Prix (croissant)",
    "Price (Descending)": "Prix (d\u00e9croissant)",
    "Product has been added to your shopping cart": "Le produit a \u00e9t\u00e9 ajout\u00e9 \u00e0 votre panier",
    "Product size": "Taille du produit",
    "Product video": "Vid\u00e9o du produit",
    "Quantity": "Quantit\u00e9",
    "Question or remark": "Question ou remarque",
    "Rate us": "\u00c9valuez-nous",
    "Read less": "Lire moins",
    "Read more": "Lire plus",
    "Recent orders": "Commandes r\u00e9centes",
    "Recent posts": "Posts r\u00e9cents",
    "Recently viewed": "Vu r\u00e9cemment",
    "People are also interested in": "Les gens s'int\u00e9ressent aussi \u00e0",
    "Related categories": "Cat\u00e9gories connexes",
    "Related products": "Produits connexes",
    "Remove": "Retirer",
    "Request password": "Demander un mot de passe",
    "Review not found": "Avis non trouv\u00e9",
    "Reviews": "Avis",
    "Rider": "Pilote",
    "SKU": "SKU",
    "Save": "Enregistrer",
    "Save address": "Enregistrer l'adresse",
    "Search": "Rechercher",
    "Search results": "R\u00e9sultats de recherche",
    "Search results for &ldquo;{term}&rdquo;": "R\u00e9sultats de recherche pour &ldquo;{term}&rdquo;",
    "Search results for \"{term}\"": "R\u00e9sultats de recherche pour \"{term}\"",
    "Secure connection": "Connexion s\u00e9curis\u00e9e",
    "Select a country": "S\u00e9lectionner un pays",
    "Select payment": "S\u00e9lectionner le paiement",
    "Select payment method": "S\u00e9lectionner la m\u00e9thode de paiement",
    "Select shipping": "S\u00e9lectionner la livraison",
    "Select shipping method": "S\u00e9lectionner la m\u00e9thode de livraison",
    "Select your address": "S\u00e9lectionner votre adresse",
    "Send": "Envoyer",
    "Ship to": "Exp\u00e9dier \u00e0",
    "Shipping": "Livraison",
    "Shipping address": "Adresse de livraison",
    "Shipping method": "M\u00e9thode de livraison",
    "Shipping methods are not available right now": "Les m\u00e9thodes de livraison ne sont pas disponibles pour le moment",
    "Shipping options will be updated during checkout": "Les frais d'exp\u00e9dition seront mis \u00e0 jour lors de la commande",
    "Show less": "Montrer moins",
    "Show more": "Montrer plus",
    "Show more photo\"s": "Montrer plus de photos",
    "Showing {productsPerPage}/{totalProducts} products": "Affichage de {productsPerPage}/{totalProducts} produits",
    "Showing {totalProductCount} products": "Affichage de {totalProductCount} produits",
    "Size Guide": "Guide des tailles",
    "Sold out": "\u00c9puis\u00e9",
    "Something went wrong while recovering your password": "Quelque chose s'est mal pass\u00e9 lors de la r\u00e9cup\u00e9ration de votre mot de passe",
    "Something went wrong, please try again": "Quelque chose s'est mal pass\u00e9, veuillez r\u00e9essayer",
    "Sort by": "Trier par",
    "Specifications": "Sp\u00e9cifications",
    "Start payment": "Commencer le paiement",
    "Store locator": "Localisateur de magasin",
    "Street": "Rue",
    "Subscribe to our newsletter": "Abonnez-vous \u00e0 notre newsletter",
    "Subscribe": "S'abonner",
    "Subscribe to the newsletter and never miss a promotion!": "Abonnez-vous \u00e0 la newsletter et ne manquez jamais une promotion!",
    "Subtotal": "Sous-total",
    "Successfully created an account, you can log in with your new credentials": "Compte cr\u00e9\u00e9 avec succ\u00e8s, vous pouvez vous connecter avec vos nouvelles informations d'identification",
    "Successfully removed address": "Adresse supprim\u00e9e avec succ\u00e8s",
    "Successfully saved address": "Adresse enregistr\u00e9e avec succ\u00e8s",
    "Successfully updated customer": "Client mis \u00e0 jour avec succ\u00e8s",
    "Successfully updated newsletter subscription": "Abonnement \u00e0 la newsletter mis \u00e0 jour avec succ\u00e8s",
    "Suitable for": "Convient pour",
    "Suited for": "Adapt\u00e9 pour",
    "Summary": "R\u00e9sum\u00e9",
    "Sustainability": "Durabilit\u00e9",
    "Tags": "\u00c9tiquettes",
    "Telephone": "T\u00e9l\u00e9phone",
    "Terms & conditions": "Conditions g\u00e9n\u00e9rales",
    "Thank you for your purchase!": "Merci pour votre achat !",
    "The account login is incorrect or your account has been temporarily disabled. Wait and try again later.": "La connexion au compte est incorrecte ou votre compte a \u00e9t\u00e9 temporairement d\u00e9sactiv\u00e9. Attendez et r\u00e9essayez plus tard.",
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "La connexion au compte \u00e9tait incorrecte ou votre compte est temporairement d\u00e9sactiv\u00e9. Veuillez attendre et r\u00e9essayer plus tard.",
    "There are no available options for this product.": "Il n'y a pas d'options disponibles pour ce produit.",
    "This personal page offers you an overview of the most recent activities. You can, among other things, view orders and update account information. Click on a link below to get started!": "Cette page personnelle vous offre un aper\u00e7u des activit\u00e9s les plus r\u00e9centes. Vous pouvez, entre autres, consulter les commandes et mettre \u00e0 jour les informations de votre compte. Cliquez sur un lien ci-dessous pour commencer !",
    "This product is currently sold out": "Ce produit est actuellement \u00e9puis\u00e9",
    "To checkout": "A la caisse",
    "To shopping cart": "Au panier",
    "Total": "Total",
    "Unable to remove address": "Impossible de supprimer l'adresse",
    "Unable to save customer": "Impossible d'enregistrer le client",
    "Unable to update newsletter subscription": "Impossible de mettre \u00e0 jour l'abonnement \u00e0 la newsletter",
    "Usage": "Utilisation",
    "VAT number": "Num\u00e9ro de TVA",
    "View details": "Voir les d\u00e9tails",
    "View more {categoryTitle}": "Voir plus de {categoryTitle}",
    "View order": "Voir la commande",
    "View product": "Voir le produit",
    "Volume": "Volume",
    "We have found the following address": "Nous avons trouv\u00e9 l'adresse suivante",
    "Welcome {name}": "Bienvenue {name}",
    "What are you looking for?": "Que cherchez-vous ?",
    "Wishlist": "Liste de souhaits",
    "Within 15 minutes you will receive an email confirming your order with all the details. If you have questions about the status of your order, please contact customer support.": "Dans les 15 minutes, vous recevrez un e-mail confirmant votre commande avec tous les d\u00e9tails. Si vous avez des questions sur l'\u00e9tat de votre commande, veuillez contacter le service client.",
    "Write a review": "\u00c9crire un avis",
    "You are now logged out and will return to our home page within 5 seconds.": "Vous \u00eates maintenant d\u00e9connect\u00e9 et serez redirig\u00e9 vers notre page d'accueil dans 5 secondes.",
    "You are now signed out": "Vous \u00eates maintenant d\u00e9connect\u00e9",
    "You are subscribed to our newsletter": "Vous \u00eates abonn\u00e9 \u00e0 notre newsletter",
    "You have no product(s) in your shopping cart.": "Vous n'avez aucun produit dans votre panier.",
    "You have not added any favorite products yet. Click on the heart next to a product while shopping to save it as your favourite.": "Vous n'avez pas encore ajout\u00e9 de produits favoris. Cliquez sur le c\u0153ur \u00e0 c\u00f4t\u00e9 d'un produit pendant vos achats pour le sauvegarder en tant que favori.",
    "You have not placed any orders yet": "Vous n'avez encore pass\u00e9 aucune commande",
    "You have not reviewed any products yet": "Vous n'avez encore \u00e9valu\u00e9 aucun produit",
    "Your address details": "Les d\u00e9tails de votre adresse",
    "Your applied coupon code is {couponCodeInput}.": "Votre code de coupon appliqu\u00e9 est {couponCodeInput}.",
    "Your details": "Vos d\u00e9tails",
    "Your email": "Votre e-mail",
    "Your message": "Votre message",
    "Your message has been sent.": "Votre message a \u00e9t\u00e9 envoy\u00e9.",
    "Your order has been successfully placed.": "Votre commande a \u00e9t\u00e9 pass\u00e9e avec succ\u00e8s.",
    "Your order id is:": "Votre ID de commande est :",
    "Your password has been updated": "Votre mot de passe a \u00e9t\u00e9 mis \u00e0 jour",
    "here": "ici",
    "incl. VAT": "TVA incl.",
    "name_s": "nom_s",
    "out of stock": "en rupture de stock",
    "price": "prix",
    "Alternatives of this product": "Alternatives de ce produit",
    "ratings": "\u00e9valuations",
    "subscribe": "s'abonner",
    "successfully logged out": "d\u00e9connect\u00e9 avec succ\u00e8s",
    "validation.email": "validation.email",
    "validation.required": "validation.requis",
    "More detailed product images": "Images de produit plus d\u00e9taill\u00e9es",
    "Subscribe to our personalized newsletter for exclusive NOMAD news, offers, and make sure you don't miss a single promotion!": "Abonnez-vous \u00e0 notre newsletter personnalis\u00e9e pour des nouvelles et offres exclusives de NOMAD, et ne manquez aucune promotion!",
    "sold out": "\u00e9puis\u00e9",
    "Show alternatives": "Montrer les alternatives",
    "All images": "Toutes les images",
    "Learn more about the": "En savoir plus sur le",
    "Customers also bought": "Les clients ont \u00e9galement achet\u00e9",
    "Suggestions for the ultimate Mistral experience": "Suggestions pour l'exp\u00e9rience Mistral ultime"
}
